import React from "react"
import heroStyles from "../scss/hero.module.scss";

/**
 * A hero page.
 */
export default function Hero({ children }) {
  return (
    <div className={heroStyles.hero}>
      <div className={heroStyles.container + " container"}>
        <div className="section">
          <span className={heroStyles.title}>
            {children}
          </span>
        </div>
      </div>
    </div>
  )
}