import React from "react"
import Layout from "../components/layout";
import Hero from "../components/hero";
import { nanoid } from 'nanoid'
import pricingStyle from "../scss/pricing.scss";
import { dynamic_content_url } from '../config.json';

/**
 * Pricing features a custom module for choosing the type
 * of pricing you are searching for.
 */
export default class Pricing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pricing: {
        single_family: [],
        apartment: []
      },
      provider: {
        push: () => {}
      }
    }
  }

  componentDidMount() {
    fetch(dynamic_content_url)
      .then(response => response.json())
      .then(data => this.setState({
        pricing: data.pricing
      }))
  }

  generateColumns(title, arr, additionalText) {
    return arr.map(({size, stripe_id, price, delim}, i) => (
      <div className="column is-2-widescreen is-one-third-tablet" key={nanoid()}>
        {!price && <br />}
        <div className={`size ${price ? '' : 'larger'}`}>{size}</div>
        {price && <div className="price">{price}{delim && <span className="smaller">{delim}</span>}</div>}
        {price && <div className="additional">{additionalText}</div>}
        <br />
        {price && <div onClick={() => this.provider.push({ quantity: 1, title, size, price, stripe_id })} className="button is-info"><i className="fas fa-plus" />&nbsp;Add</div>}
        {!price && <div className="button" onClick={() => this.provider.push({ quantity: 1, title, size })}>Get a Quote</div>}
        {arr.length != i+1 && <div className="divider" />}
        <br /><br />
      </div>
    ));
  }

  render() {
    let { 
      single_family, 
      apartment,  
      photos,
      mattertag,
      apartment_shown
    } = this.state.pricing;
    return (
      <Layout fetchOrderProvider={provider => this.provider = provider }>
        <Hero>Pricing</Hero>
        <div className="background">
          <div className="container">
            <div className="section desc">
              We price based on the type of property you'd like us to scan. Typical pricing is 10¢/sq ft.
            </div>
          </div>
          <div className="price-options">
            <div className="section has-background-light single-family">
              <div className="container">
                <h1 className="title">Single Family</h1>
                <h2 className="subtitle">Tiered Pricing</h2>
                <p>Included in any single-family purchase is the initial scan of the home and hosting of the tour for 3 months or until sold, whichever comes first.</p>
                <div className="section">
                  <div className="columns is-multiline">
                    {this.generateColumns('Single Family',single_family)}
                  </div>
                </div>
              </div>
            </div>
            {apartment_shown && <div className="section apartment">
              <div className="container">
                <h1 className="title">Apartment</h1>
                <h2 className="subtitle">Tiered Pricing</h2>
                <p>Included in any apartment subscription is the initial scan of the home and hosting for 3 months.</p>
                <div className="section">
                  <div className="columns is-multiline">
                    {this.generateColumns('Apartment', apartment)}
                  </div>
                </div>
              </div>
            </div>}
            <div className={`section ${apartment_shown && 'has-background-light'}`}>
              <div className="container">
                <div className="columns">
                  <div className="column">
                    <h1 className="title">15 Quality Photos</h1>
                    <h2 className="subtitle">No better price around.</h2>
                    <p>While we are scanning your space for hosting on Matterport, we can additionally use our photography skills to get you 15 high-quality photos of the space. Because this is an addon to a scan, it's a better price than you'll get from most photographers.</p>
                  </div>
                  <div className="column">
                    <h1 className="title">Mattertag™</h1>
                    <h2 className="subtitle">Extra info in your tours. Learn more <a href="https://matterport.com/blog/introducing-mattertagtm-posts">here</a>.</h2>
                    <p>Using Mattertag™ Posts, you can anchor extra information inside of your space. This lets you inform your visitors more about the space, or provide additional marketing avenues for you.</p>
                  </div>
                </div>
                <div className="columns">
                  {photos && <div className="column">
                    <div className="price">{photos.price}</div>
                    <br />
                    <div 
                      className="button is-info" 
                      onClick={() => this.provider.push({ 
                        quantity: 1, 
                        title: '15 Quality Photos', 
                        price: photos.price, 
                        stripe_id: photos.stripe_id 
                      })}><i className="fas fa-plus" />&nbsp;Add</div>
                  </div>}
                  {mattertag && <div className="column">
                    <div className="price">{mattertag.price}<span className="smaller">/tag</span></div>
                    <br />
                    <div 
                      className="button is-info" 
                      onClick={() => this.provider.push({ 
                        quantity: 1, 
                        title: 'Mattertag', 
                        price: mattertag.price, 
                        stripe_id: mattertag.stripe_id 
                      })}><i className="fas fa-plus" />&nbsp;Add</div>
                  </div>}
                </div>  
              </div>
            </div>
            <div className={`section ${apartment_shown ? '' : 'has-background-light'} commercial-retail`}>
              <div className="container">
                <h1 className="title">Commercial or Retail</h1>
                <h2 className="subtitle">Priced on a combination of factors. Starts at 10¢ per square foot.</h2>
                <div className="button" onClick={() => this.provider.push({ quantity: 1, title: 'Commercial/Retail Property' })}>Get a Quote</div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}